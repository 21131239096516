@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import 'bootstrap/dist/css/bootstrap.min.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, sans-serif;
    text-decoration: none;
}

li {
    list-style: none;
}

body {
    color: white;
}

a {
    text-decoration: none;
}

i {
    cursor: pointer;
}


@font-face {
    font-family: 'Material Icons Sharp';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconssharp/v108/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvR.woff2) format('woff2');
}

.material-icons-sharp {
    font-family: 'Material Icons Sharp';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.search-input::-webkit-search-cancel-button {
    transition: all 200ms ease-in-out;
    display: inline-block;
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    margin-left: -1.5em;
    background-color: transparent;
    background-image: url(./assets/close.svg);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}