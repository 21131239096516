.auth-container {
  background: #ffb722;
  border-radius: 10px;
  display: flex;
}
.auth-container .regis-midd-box {
  padding: 92.2px 0px;
}
.forgot-midd-box {
  padding: 17.7px 0px;
}
.sidebar.close .link_name {
  font-size: 10px;
}
.sidebar.close .sidebar-links li.sidebar-link {
  padding-top: 0px;
}
.sidebar.close .sidebar-links li.sidebar-link i {
  font-size: 20px;
}

@media only screen and (max-width: 420px) {
  .auth-container {
    flex-direction: column;
  }
}

.auth-background {
  flex-direction: column;
  background-color: black;
  height: 100vh;
  width: 100vw;
}

.auth-background .logo img {
  width: 40px;
}

.auth-left {
  width: 100px;
  height: 100%;
  min-height: 5rem;
  border-radius: 10px 0px 0px 10px;
  padding: 30px;
}

.auth-right {
  width: 400px;
  background: white;
  border-radius: 0px 10px 10px 0px;
  padding: 30px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 420px) {
  .auth-right {
    width: 100%;
   
  }

  .auth-container {
    width: 80%;
  }

  #upload_content {
    margin-left: 50px;
  }
}

.auth-input {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
  padding: 4px;
  margin: 6px;
  background-color: transparent;
}

.auth-input:focus {
  background-color: transparent;
  outline: none;
}

.error {
  font-size: 14px;
  color: red;
  font-weight: bold;
}

.float-right {
  float: right;
}

.link-button {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* upload */

.text-file {
  font-size: 12px;
}

/* sidebar */
.sidebar {
  height: 100%;
  width: 200px;
  z-index: 100;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-innerdiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.sidebar.close {
  width: 50px;
}

.sidebar .link_name {
  overflow: hidden;
  width: 100%;
  transition: all 200ms linear;
}

/* .sidebar.close .link_name {
    display: none !important;
    opacity: 0;
    visibility: hidden;
} */

@media only screen and (max-width: 420px) {
  .sidebar {
    position: absolute;
    background-color: black;
  }

  .listing-container {
    margin-left: 50px;
  }
}

.sidebar-links {
  padding: 20px 0 0px 0;
  /* overflow: hidden; */
  background-color: black;
  flex: 1;
  margin-bottom: 0;
}

.sidebar .attachment {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 45%;
  right: -20px;
  overflow: hidden;
  color: var(--bs-warning);
  position: absolute;
  width: 20px;
  height: 30px;
  padding: 2px;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background-color: rgb(0, 0, 0);
}

.sidebar .attachment i {
  transition: all 300ms linear;
  transform: rotate(180deg);
}

.sidebar.close .attachment i {
  transform: rotate(0deg);
}

.logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.auth-background .logo-details {
  height: 100px;
}

.logo-details i {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 50px;
  text-align: center;
  line-height: 50px;
}

.auth-background .logo-details i {
  font-size: 34px;
  height: 56px;
  min-width: 64px;
}

.logo-details i img {
  height: 38px;
  width: 38px;
  text-align: center;
}

.auth-background .logo-details img {
  height: 60px;
  width: 60px;
}

.logo-details h2 {
  font-size: 1.5rem;
  color: #f0a500;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
  white-space: nowrap;
}

.auth-background .logo-details h2 {
  font-size: 1.4rem;
}

.logo-details .logo_name {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  white-space: nowrap;
}

.auth-background .logo_name {
  font-size: 28px;
}

.sidebar .sidebar-link {
  /* overflow: hidden; */
  list-style: none;
  transition: all 0.4s ease;
  color: #fff;
  cursor: pointer;
  padding: 3px 6px;
  padding-left: 0;
}

.sidebar .sidebar-link .link {
  color: inherit;
}

.sidebar .sidebar-link:hover {
  color: #f0a500;
  background-color: #1d1b31;
}

.sidebar-link i {
  min-width: 50px;
  font-size: 20px;
  text-align: center;
}

/* .sidebar-link span {
  min-width: max-content;
} */

/* listing */

.listing-container {
  flex: 1;
}

/* search */
.search-main {
}

.search-main #cross {
  color: white;
  cursor: pointer;
}

.search-main .search-input {
  border-radius: 6px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  padding: 4px;
}

.search-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #333 inset;
  -webkit-text-fill-color: white;
}

.search-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #333 inset !important;
  -webkit-text-fill-color: white;
}

/* List */
.list-container {
  position: relative;
  overflow-y: scroll;
  flex: 1;
}

.list-container::-webkit-scrollbar {
  display: none;
}

.list-container .list-item {
  overflow: visible;
}

.list-container .list-item.active {
  background-color: rgb(56, 56, 56);
}

.list-container .card-content {
  border: 3px solid transparent;
  cursor: pointer;
  transition: all 100ms linear;
}

.active .card-content {
  border: 3px solid var(--bs-warning);
}

.list-container .card-content .card-title {
  transition: all 100ms linear;
}

.list-container .card-content:hover .card-title {
  color: var(--bs-warning);
}

.list-container .article-list .list-item {
  list-style: none;
}

.list-container .article-list .list-item .card-heading {
  color: #fff;
  flex: 1;
  transition: all 150ms ease;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.list-container .card-content:hover .card-heading {
  color: #f0a500;
}

.list-container .article-list .list-item .card-date {
  width: 15%;
}

.list-container .article-list .list-item .card-desc {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  transition: all 150ms ease;
}

.list-container .article-list .list-item .card-url {
  transition: all 150ms ease;
}

.list-container .card-content:hover .card-url {
  color: #f0a500;
}

/* view */

.right-view {
  flex: 1;
  width: -webkit-fill-available;
  overflow: auto;
}

@media only screen and (max-width: 420px) {
  .right-view {
    position: absolute;
    height: 100%;
    z-index: 99;
    margin-left: 50px;
  }
}

.right-view::-webkit-scrollbar {
  display: none;
}

.right-view .view-heading {
  color: #ffb722;
  font-weight: bold;
  font-size: 155%;
}

.right-view .edit-view-heading {
  outline: none;
  padding: 4px;
  background-color: rgb(45, 45, 45);
  border: none;
  border-radius: 12px;
  width: 100%;
  color: inherit;
}

.right-view .view-desc-input {
  width: 100%;
  background-color: #1d1b31;
  color: aliceblue;
  border-radius: 10px;
}

.list-group-item {
  transition: all 400ms ease;
}

.list-group-item:hover {
  transform: translateY(-4%);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* popup container */

.popups-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

.popups-container .popups-content {
  position: relative;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 0, 0, 0.35);
}
