.errMsg {
    font-size: 10px;
    color: red;
}

.successMsg {
    font-size: 13px;
    font-weight: bold;
    color: rgb(24, 255, 82);
}

.Cascade-module_dropdown__3z27U input {
    width: '20px' !important;
}


.dropdown {
    z-index: 100;
    list-style: none;
}

.dropdown li {
    position: relative;
    z-index: 99;
}

.dropdown li a {
    transition: all 300ms linear;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    display: block;
}

.dropdown-ul {
    transition: all 300ms linear;

}

.dropdown li ul {
    position: absolute;
    left: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    line-height: normal;
    background-color: #333;
}

.dropdown li ul li a {
    text-align: left;
    color: #cccccc;
    font-size: 14px;
    padding: 10px;
    display: block;
    white-space: nowrap;
}

.dropdown li ul li a:hover {
    background-color: #FFB722;
    color: #ffffff;
}

.dropdown li ul li ul {
    left: 100%;
    top: 0;
}

.dropdown-ul li:hover>a {
    color: #FFB722;
}

.dropdown-ul li:hover>.dropdown-ul {
    display: block;
}

.text-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.text-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.text-clamp-7 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}