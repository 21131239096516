.user-main-container {
    background-color: #11101d;
    height: 100vh;
}
.topic-list.none-selected>div>div {
  background-color: transparent;
}

@media only screen and (max-width: 420px) {
  .user-main-container {
    background-color: #11101d;
    height: 106vh;
}
}